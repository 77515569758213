import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.less';

function LoveStationReward() {
  const history = useHistory();
  const handleGoMenu = () => {
    history.push('/love/station-menu');
  };

  return (
    <>
      <div className={styles['love-station-reward']}>
        <div className={styles['top']}></div>
        <div className={styles['question']}>
          <div className={styles['title']}>恭喜你中奖</div>
          <div className={styles['gift-icon']}></div>
          <div className={styles['receive-btn']}>立即领取</div>
        </div>
      </div>
    </>
  );
}
export default memo(LoveStationReward);
