// 爱心驿站首页
import { memo, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './index.less';
import { Modal, Toast } from 'antd-mobile';
import { getCookie } from '../../utils/cookie';
import { postNativeRequestPromise } from '../../utils/naviteRequestUtil';
import { ListView, Popover } from 'antd-mobile';

function LoveStationIce() {
  const [dataList, setDataList] = useState([]);
  const [visible, setVisible] = useState(false);
  const listDataSource = new ListView.DataSource({
    rowHasChanged: (row1, row2) => {
      return true;
    },
  });
  const [dataSource, setDataSource] = useState(listDataSource);
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const INITPAGESIZE = 100;
  const history = useHistory();
  const location = useLocation();
  const [height, setHeight] = useState(1);
  const listviewRef = useRef(null);
  const [list, setList] = useState([]);
  const [isFetching, setIsFetching] = useState(false); //是否持续加载
  const [popOverVisible, setPopOverVisible] = useState(false);

  useEffect(() => {
    postNativeRequestPromise("/inner/h5/visit/addRecord", {pageName: 'stationIce'}).then(resp => {
      console.log(resp);
    })
  }, [])
  useEffect(() => {
    setTimeout(() => {
      handleHasLogin();
    }, 1000);
  }, []);

  const handleHasLogin = () => {
    let token = getCookie('x-token');
    if (token == '' || token == null) {
      Modal.alert('未登录', '您还没有登录，请先登录！！', [
        {
          text: '取消',
          onPress: () => {},
        },
        {
          text: '去登录',
          onPress: () => {
            localStorage.setItem('x-path', location.pathname);
            setTimeout(() => {
              history.push('/auth');
            }, 1000);
          },
        },
      ]);
      // Toast.info(
      //   '你还未登录！后期等登录接口会跳转登录页面进行登录，现在可直接查看改页面！'
      // );
      getPageData(1, INITPAGESIZE);
    } else {
      getPageData(1, INITPAGESIZE);
    }
  };
  const getPageData = (current, pageSize) => {
    postNativeRequestPromise('/inner/h5/voucher/page', {
      current: current,
      size: pageSize,
    })
      .then(res => {
        const resAnnounceList = res.data.records;
        if (!resAnnounceList || resAnnounceList.length < pageSize) {
          setIsFetching(false);
        } else {
          setIsFetching(true);
        }
        setList(resAnnounceList);
        if (current === 1) {
          setDataList([...res.data.records]);
          setDataSource(dataSource.cloneWithRows([...res.data.records]));
        } else {
          setDataList([...dataList, ...res.data.records]);
          setDataSource(
            dataSource.cloneWithRows([...dataList, ...res.data.records])
          );
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  const row = item => {
    return (
      <div className={styles['btn-group-item']}>
        <div className={styles['left']}>
          <div className={styles['left-name']}>
            {item.storeName.length > 12
              ? item.storeName.slice(0, 9) + '...'
              : item.storeName}
            {/* {item.storeName} */}
          </div>
          {/* <Popover
            mask={false}
            visible={popOverVisible}
            onSelect={(node, index) => {
              setPopOverVisible(false);
            }}
            onVisibleChange={v => setPopOverVisible(v)}
            placement="topLeft"
            overlay={item.storeAddress}
            align={{
              overflow: { adjustY: 0, adjustX: 0 },
            }}
          > */}
          <div className={styles['left-address']}>
            {item.storeAddress.length > 12
              ? item.storeAddress.slice(0, 9) + '...'
              : item.storeAddress}
          </div>
          {/* </Popover> */}
        </div>
        <div className={styles['divider']}></div>
        <div className={styles['right']}>
          <div className={styles['right-main']}>
            <div className={styles['value']}>
              {item.voucherResidue} / {item.voucherTotal}
            </div>
            <button
              className={styles['receive-btn']}
              onClick={() => handleReceive(item)}
            >
              领取
            </button>
          </div>
        </div>
      </div>
    );
  };
  const handleReceive = item => {
    postNativeRequestPromise('/inner/h5/voucher/getVoucher', {
      storeId: item.storeId,
    })
      .then(res => {
        if (res.code == 200) {
          Toast.info('领取成功，请到我的券包查看！');
          getPageData(1, INITPAGESIZE);
        } else {
          Toast.info(res.message);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  const onEndReached = () => {
    if (!isFetching) {
      return;
    }
    setPageNum(pageNum + 1);
    getPageData(pageNum + 1, INITPAGESIZE);
  };
  const handleGoMyTicket = () => {
    let token = getCookie('x-token');
    if (token == '' || token == null) {
      Modal.alert('未登录', '您还没有登录，请先登录！！', [
        {
          text: '取消',
          onPress: () => {},
        },
        {
          text: '去登录',
          onPress: () => {
            localStorage.setItem('x-path', location.pathname);
            setTimeout(() => {
              history.push('/auth');
            }, 1000);
          },
        },
      ]);
      // Toast.info(
      //   '你还未登录！后期等登录接口会跳转登录页面进行登录，现在可直接查看改页面！'
      // );
      // getPageData(1, INITPAGESIZE);
    } else {
      history.push('/love/station-ticket');
    }
    
  };
  const handleGoMenu = () => {
    history.push('/love/station-menu');
  };
  //popup
  const handleClose = () => {};

  return (
    <>
      <div className={styles['love-station-ice']}>
        <div
          className={styles['activity-rules']}
          onClick={() => setVisible(true)}
        >
          活动规则
        </div>
        <div className={styles['block']}></div>
        <div className={styles['text']}></div>
        <ListView
          ref={listviewRef}
          renderBodyComponent={() => <div />}
          dataSource={dataSource}
          className={styles['btn-group']}
          renderFooter={() => (
            <div
              style={{
                textAlign: 'center',
                fontSize: '15px',
                marginTop: '10px',
              }}
              // onClick={onEndReached}
            >
              {/* {isFetching ? '点击加载下一页' : '暂无更多'} */}
              {isFetching ? '' : ''}
            </div>
          )}
          renderRow={row}
          // onEndReachedThreshold={INITPAGESIZE}
          // onEndReached={onEndReached}
        ></ListView>

        <div className={styles['bottom-btn']}>
          <button className={styles['btn']} onClick={handleGoMenu}>
            返回
          </button>
          <button className={styles['btn']} onClick={handleGoMyTicket}>
            我的券包
          </button>
        </div>
        {visible && (
          <div>
            <div className="am-picker-popup-mask"></div>
            <div className="am-picker-popup-wrap " role="dialog">
              <div
                role="document"
                className="am-picker-popup"
                style={{
                  height: '100vh',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  margin: 'auto',
                }}
                onClick={() => setVisible(false)}
              >
                <div
                  className="rule-body"
                  style={{
                    position: 'absolute',
                    left: '63px',
                    top: '22vh',
                    width: '70%',
                    height: '55%',
                  }}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                      }}
                    >
                      1、活动时间：
                    </span>
                    2024年8月28日-10月30日
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                      }}
                    >
                      2、活动门店：
                    </span>
                    肯德基杨箕店、 肯德基金聚店、 肯德基花都绿地空港KCOFFEE、
                    肯德基元创KCOFFEE、 肯德基M+STUDIO创工场KCOFFEE
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                      }}
                    >
                      3、派券数量：
                    </span>
                    活动期间，每个门店限量提供400只免费雪糕，领完即止；
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                      }}
                    >
                      4、领券方式：
                    </span>
                    扫描活动二维码--{'>'}领取雪糕券--{'>'}点击领取--{'>'}
                    我的券包--{'>'}向服务员展示雪糕券--{'>'}
                    兑换雪糕（由服务员点击进行核销），即可得到免费雪糕一份。
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                    }}
                  >
                    <span>5、注意事项：</span>
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    ①活动仅限户外劳动者参与；
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    ②每位参与者（同一设备、同一手机号）每周仅限领取一张雪糕券；
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    ③雪糕券有效期为本周有效。
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                      }}
                    >
                      6、雪糕产品及包装以餐厅实际供应实物为准。
                    </span>
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                      }}
                    >
                      7、活动最终解释权归主办方所有。
                    </span>
                  </p>
                  {/* <div class="kfcList">
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '11px',
                        lineHeight: '15px',
                        letterSpacing: '.5px',
                        fontWeight: 300,
                      }}
                    >
                      1、基盛万科肯德基：广州市番禺区市桥街盛兴大街盛商业中心A区首层
                    </p>
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '11px',
                        lineHeight: '15px',
                        letterSpacing: '.5px',
                        fontWeight: 300,
                      }}
                    >
                      2、江夏肯德基：广州市白云区江夏村北二路一巷江夏新天地广场首层
                    </p>
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '11px',
                        lineHeight: '15px',
                        letterSpacing: '.5px',
                        fontWeight: 300,
                      }}
                    >
                      3、东圃四季肯德基：广州市天河区东圃大马路4号时尚荟B区二层
                    </p>
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '11px',
                        lineHeight: '15px',
                        letterSpacing: '.5px',
                        fontWeight: 300,
                      }}
                    >
                      4、上渡路肯德基：广州市海珠区上渡路66号金骏街20号商铺
                    </p>
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '11px',
                        lineHeight: '15px',
                        letterSpacing: '.5px',
                        fontWeight: 300,
                      }}
                    >
                      5、东平肯德基：广州市白云区东平大道8号东泰商贸广场金灏堡一层
                    </p>
                    <p
                      style={{
                        color: '#fff',
                        fontSize: '11px',
                        lineHeight: '15px',
                        letterSpacing: '.5px',
                        fontWeight: 300,
                      }}
                    >
                      6、云山大道餐厅：广州市花都区云山大道29号首层及二层
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <Popup
          visible={visible}
          position="center"
          onClose={() => setVisible(false)}
        >
          <div className={styles['popup-bg']} style={{ padding: 20 }}>这是一个居中的弹出层内容</div>
        </Popup> */}
      </div>
    </>
  );
}
export default memo(LoveStationIce);
