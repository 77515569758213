import axios, { AxiosRequestConfig } from 'axios';
import { Toast, Modal } from 'antd-mobile';

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status == 401) {
      Modal.alert('登录信息失效', '登录信息已失效请重新登录', [
        {
          text: 'Ok',
          onPress: () => {
            console.log('ok');
            document.cookie = 'x-token=; path=/; expires=' + new Date(0).toUTCString();
            document.cookie = 'x-phone=; path=/; expires=' + new Date(0).toUTCString();
            // document.cookie = 'x-token' + '=' + '' + '; ' + -1;
          },
        },
      ]);
    } else if (error.response.status == 500) {
      Toast.info('参数错误', 1);
    }
  }
);

export function postRequest(
  url: string,
  data?: any,
  options?: AxiosRequestConfig
): Promise<any> {
  return axios.post(encodeURI(url), data, options);
}
export function getWX(url: string, data?: any): Promise<any> {
  return axios.get(encodeURI(url), data);
}
export function getRequest(url: string, data?: any): Promise<any> {
  return axios.get(encodeURI(url), data);
}
