import React  from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { IntlProvider } from 'react-intl';
import Router from './router';
import { intlConfig, loginDetail } from './store';
import { useEffect } from 'react';
import { postNativeRequestPromise, getUserInfo } from './utils/naviteRequestUtil';
import {isIOS, jsSdkConfig} from './utils/toolWx';

function App() {
  if (isIOS() ) {
    jsSdkConfig()
  }
  const [config, setConfig] = useRecoilState(intlConfig);
  const setLoginDetail = useSetRecoilState(loginDetail);
  useEffect(() => {
    const userInfo = getUserInfo();
    setLoginDetail(userInfo);
    postNativeRequestPromise("/inner/h5/visit/addRecord", {pageName: 'enter'}).then(resp => {
      console.log(resp);
    })
  }, [])
  return (
    <IntlProvider messages={config.message} locale={config.locale}>
      <Router />
    </IntlProvider>
  );
}

export default App;
