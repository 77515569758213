import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.less';
import { postNativeRequestPromise } from '../../utils/naviteRequestUtil';
// import { Toast, Modal } from '_antd-mobile@2.3.4@antd-mobile';
import { Toast, Modal } from 'antd-mobile';

function LoveStationTicket() {
  const history = useHistory();
  const [isHave, setIsHave] = useState(false);
  const [pageData, setPageData] = useState({});
  useEffect(() => {
    getData();
  }, []);

  const handleGoMenu = () => {
    history.goBack();
  };
  const getData = () => {
    postNativeRequestPromise('/inner/h5/voucher/getMyTodayVoucher', {})
      .then(res => {
        if (res && res.data && (res.data.status == 0 || res.data.status == 1)) {
          setPageData(res.data);
          setIsHave(true);
        } else {
          // Toast.info(res.message);
          setIsHave(false);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  const handleExchange = () => {
    postNativeRequestPromise('/inner/h5/voucher/exchangeVoucher', {
      voucherRecordId: pageData.voucherRecordId,
    })
      .then(res => {
        if (res && res.code == '00000') {
          Toast.info('兑换成功！');
          getData();
        } else {
          Toast.info(res.message);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  const handleDel = () => {
    if (!isHave) {
      Toast.info('尚未领取雪糕券，不可兑换！');
      return;
    }
    Modal.alert('确认', '该步骤应由店员操作，确认领取吗？', [
      { text: '取消', onPress: () => {} },
      {
        text: '领取',
        onPress: () => {
          handleExchange();
        },
      },
    ]);
  };
  return (
    <>
      <div className={styles['love-station-ticket']}>
        <div className={styles['block']}></div>
        <div className={styles['icon']}></div>
        {isHave ? (
          pageData.status == 0 ? (
            <div className={styles['text']}>
              <div>此券限当周使用</div>
            </div>
          ) : (
            <div className={styles['text']}>
              <div>您已经兑换本周雪糕券</div>
            </div>
          )
        ) : (
          <>
            <div className={styles['sorry-text']}>对不起，你尚未领取雪糕券</div>
          </>
        )}
        <div className={styles['btn-group']}>
          <button className={styles['btn']} onClick={handleDel}>
            请店员操作领取
          </button>
          <button className={styles['btn']} onClick={handleGoMenu}>
            返回
          </button>
        </div>
        {pageData.storeName ? (
          <div className={styles['address']}>
            <div className={styles['row']}>限{pageData.storeName}店使用</div>
            <div className={styles['row']}>营业时间</div>
            <div className={styles['row']}>{pageData.businessHours}</div>
          </div>
        ) : (
          <div className={styles['address']}>
            {/* <div className={styles['title']}>领取门店营业时间</div>
            <div className={styles['row']}>
              肯德基杨萁店(每日24小时) 肯德基金聚店(每日24小时)
            </div>
            <div className={styles['row']}>
              花都区绿地空港KCOFFEE(周-~周五07:30-19:00)
            </div>
            <div className={styles['row']}>
              天河区元创KCOFFEE(周一~周五07:00-19:00)
            </div>
            <div className={styles['row']}>
              海珠区创工场KCOFFEE(周一~周五07:00-18:00)
            </div> */}
          </div>
        )}
      </div>
    </>
  );
}
export default memo(LoveStationTicket);
