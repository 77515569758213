//授权页面
import { memo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Toast, Modal, InputItem } from 'antd-mobile';
import axios from 'axios';
import { postNativeRequestPromise } from '../../utils/naviteRequestUtil';
import styles from './index.less';
import { setCookie } from '../../utils/cookie';
import throttle from 'lodash/throttle';

function Auth() {
  /**
   * 验证码相关
   * 1、验证码值
   * 2、是否显示验证码按钮
   * 3、验证码时间
   */
  const [code, setCode] = useState();
  const [codeText, setCodeText] = useState('获取验证码');
  const [show, setShow] = useState();
  const [count, setCount] = useState();
  const history = useHistory();
  /**
   * 手机号相关
   * 1、手机号
   * 2、错误信息
   */
  const [phoneValue, setPhoneValue] = useState('');
  const [hasError, setHasError] = useState(false);

  /**
   * 获取用户code
   */
  // useEffect(() => {
  //   getCode();
  // }, []);

  /**
   * 倒计时
   */
  useEffect(() => {
    setTimeout(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {
        setShow(false);
      }
    }, 1000);
  }, [count]);

  useEffect(() => {
    // document.title = '登录页面';
  }, []);

  /**
   * 获取code
   * 1、获取code，如果没有code则需要登录，如果有则向后端传code，并登录
   */
  const getCode = () => {
    let urlCode = getUrlCode();
    // let urlCode = '02136qFa1sqnqD0GK5Ia1LGRyc036qFG';
    if (urlCode == null || urlCode === '') {
      // 如果没有code，则去请求
      window.location.href =
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc947c8eb530ebc03&redirect_uri=https%3A%2F%2Fyhzhdn.com%2Fauth&response_type=code&scope=snsapi_base#wechat_redirect';
    } else {
      // 将code传给后端
      getOpenId(urlCode);
    }
  };
  /**
   * 获取微信跳转过来的code值
   */
  const getUrlCode = () => {
    var url = window.location.search;
    var theRequest = new Object();
    if (url.indexOf('?') != -1) {
      var str = url.substr(1);
      var strs = str.split('&');
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1];
      }
    }
    return theRequest.code;
  };

  /**
   * 获取openId
   */
  const getOpenId = urlCode => {
    axios
      .get(encodeURI('/mobile/api/user/wechat/openId'), {
        params: { code: urlCode },
      })
      .then(res => {
        if (res && res.data && res.data.data && res.data.data.openId) {
          localStorage.setItem('x-app', res.data.data.openId);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  /**
   * 获取验证码
   * 1、验证输入手机号是否合法
   * 2、请求验证码接口
   * 3、倒计时
   */
  const handleCodeClick = async () => {
    try {
      if (!phoneValue || hasError) {
        Toast.info('请先输入手机号！');
        return;
      } else {
        setShow(!show);
        handleCountdown();
        setCodeText('重新获取');
        await sendCode();
      }
    } catch (err) {
      console.error(err);
    }
  };
  /**
   * 处理倒计时
   */
  const handleCountdown = () => {
    setCount(60);
  };
  /**
   * 发送验证码
   */
  const sendCode = () => {
    Toast.loading('获取验证码', 0);
    return new Promise((resolve, reject) => {
      postNativeRequestPromise('/inner/h5/sms/login/getSnsCode', {
        mobile: phoneValue,
      })
        .then(res => {
          if (res.data == 200) {
            Toast.info('验证码发送成功！');
            return resolve(res.message);
          } else {
            Toast.info(res.message);
            setShow(false);
            return reject(res.message);
          }
        })
        .catch(err => {
          Toast.hide();
          console.error(err);
          return reject(err);
        });
    });
  };
  /**
   * 处理验证码变化
   */
  const handleCodeChange = value => {
    setCode(value);
  };
  /**
   * 处理手机号变化
   */
  const handlePhoneChange = value => {
    if (value.replace(/\s/g, '').length < 11) {
      setHasError(true);
    } else {
      setHasError(false);
    }
    setPhoneValue(value);
  };
  /**
   * 手机号错误点击
   */
  const handelPhoneError = () => {
    if (hasError) {
      Toast.info('请输入正确的手机号');
    }
  };
  /**
   * 处理登录
   * 1. 登录成功将token存储在local，并且跳转到上一页。需要将用户的openId传给后端做关联
   */
  const handleBtnLogin = () => {
    if (!phoneValue) {
      Toast.info('请输入手机号');
      return;
    } else if (!code) {
      Toast.info('请输入验证码');
      return;
    }
    postNativeRequestPromise('/inner/h5/sms/login/loginByCode', {
      mobile: phoneValue,
      code: code,
    })
      .then(async res => {
        if (res.data.token) {
          setCookie('x-token', res.data.token, 72);
          setCookie('x-phone', phoneValue, 72);
          Toast.info('登录成功');
          let path = localStorage.getItem('x-path');
          history.push(path);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  /**
   * 处理将openId传给后端
   */
  const handleOpenIdToBack = () => {
    return new Promise((resolve, reject) => {
      postNativeRequestPromise('/public/user/auth/bind', {
        appId: 'wxc947c8eb530ebc03',
        openId: localStorage.getItem('x-app'),
      })
        .then(res => {
          localStorage.removeItem('x-app');
          return resolve();
        })
        .catch(err => {
          console.error(err);
          return reject(err);
        });
    });
  };
  /**
   * 处理取消
   */
  const handleBtnCancel = () => {
    // Modal.alert('取消登录', '确定取消登录？', [
    //   { text: '取消', onPress: () => console.log('cancel') },
    //   {
    //     text: '确定',
    //     onPress: () => {
    //       let path = localStorage.getItem('x-path');
    //       history.push(path);
    //     },
    //   },
    // ]);
    history.goBack()
  };

  // 容器
  return (
    <>
      {/* 授权框 */}
      <div>
        <div className="am-picker-popup-mask"></div>
        <div className="am-picker-popup-wrap" role="dialog">
          <div role="document" className="am-picker-popup">
            <div className="am-picker-popup-content">
              <div className="am-picker-popup-body">
                <div>
                  <div className={styles['authorized-container']}>
                    {/* 标题 */}
                    <span className={styles['authorized-container-title']}>
                      手机号码登录
                      <span
                        className={
                          styles['authorized-container-title-children']
                        }
                      >
                        {/* 未注册过的手机号将自动创建账号 */}
                      </span>
                    </span>
                    {/* 内容 */}
                    <div className={styles['authorized-container-main']}>
                      <InputItem
                        placeholder="请输入手机号"
                        error={hasError}
                        onErrorClick={handelPhoneError}
                        onChange={handlePhoneChange}
                        value={phoneValue}
                        maxLength={'11'}
                        className={styles['authorized-container-main-phone']}
                      ></InputItem>
                      <InputItem
                        placeholder="请输入验证码"
                        onChange={handleCodeChange}
                        value={code}
                        className={styles['authorized-container-main-phone']}
                        maxLength={'4'}
                      ></InputItem>
                      {!show && (
                        <button
                          className={styles['authorized-container-main-code']}
                          onClick={throttle(handleCodeClick, 10000)}
                        >
                          {codeText}
                        </button>
                      )}
                      {show && (
                        <span
                          className={
                            styles['authorized-container-main-code-time']
                          }
                        >
                          {count} s
                        </span>
                      )}
                    </div>
                    {/* 登录 */}
                    <div className={styles['authorized-container-btn']}>
                      <button
                        className={styles['authorized-container-btn-cancel']}
                        onClick={handleBtnCancel}
                      >
                        取消
                      </button>
                      <button
                        className={styles['authorized-container-btn-login']}
                        onClick={handleBtnLogin}
                      >
                        登录
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(Auth);
