import { getWxConfig } from './naviteRequestUtil';
import wx from 'weixin-jsapi';

console.log(wx, 'wx');
export function isWechat() {
  // 判断是否在微信环境
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/micromessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}

export function isIOS() {
  let isIphone = navigator.userAgent.includes('iPhone');
  let isIpad = navigator.userAgent.includes('iPad');
  return isIphone || isIpad;
}

export async function jsSdkConfig() {
  const res = await getWxConfig(window.location.href.split('#')[0]);
  // console.log(res);
  if (res && res.data && res.data.data) {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: res.data.data.appId, // 必填，公众号的唯一标识
      timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
      nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
      signature: res.data.data.signature, // 必填，签名
      jsApiList: [
        'getLocalImgData',
        'chooseImage',
        'previewImage',
        'getLocation',
        'onMenuShareTimeline', //分享到朋友圈
      ], // 必填，需要使用的JS接口列表
      openTagList: ['wx-open-launch-weapp'],
    });
  }
}
