// 爱心驿站首页
import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.less';
import classnames from 'classnames';

function LoveStation() {
  const [startY, setStartY] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const history = useHistory();
  const [outPage, setOutPage] = useState(false);

  const handleGoMenu = () => {
    history.push('/love/station-menu');
  };
  const handleTouchStart = e => {
    setStartY(e.touches[0].clientY);
  };
  const handleTouchMove = e => {
    const currentY = e.touches[0].clientY;
    const diff = currentY - startY;
    if (diff < -50) {
      setIsSliding(true); // 设置滑动状态为true
      setOutPage(true);
      setTimeout(() => {
        history.push('/love/station-menu');
      }, 1500);
    }
  };
  return (
    <>
      <div
        className={classnames(
          styles['love-station'],
          outPage ? styles['out-page'] : ''
        )}
        onClick={handleGoMenu}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <div className={styles['logo']}>
        </div>
        <div className={styles['logo-right']}>
        </div>
        <div className={styles['text']}></div>
        <div className={styles['bottom-arrow']}>
          <div className={styles['icon']}></div>
        </div>
      </div>
    </>
  );
}
export default memo(LoveStation);
