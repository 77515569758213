// 爱心驿站首页
import { memo, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.less';
import { postNativeRequestPromise } from '../../utils/naviteRequestUtil';
// import { Toast } from '_antd-mobile@2.3.4@antd-mobile';
import { Toast } from 'antd-mobile';
// import { LeftOutline } from 'antd-mobile-icons'
import { debounce } from 'lodash';

function LoveStationPhoto() {
  const [list, setList] = useState([]);
  const [inStart, setInStart] = useState();
  const [inEnd, setInEnd] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    postNativeRequestPromise("/inner/h5/visit/addRecord", {pageName: 'stationPhoto'}).then(resp => {
      console.log(resp);
    })
  }, [])
  useEffect(() => {
    getPageData(1, 1);
  }, []);
  const handleGoMenu = () => {
    // history.push('/love/station-menu');
    console.log('点击了相册得返回');
    history.goBack();
  };
  const getPageData = (current, pageSize) => {
    postNativeRequestPromise('/inner/album/manage/page', {
      current: current,
      size: pageSize,
    })
      .then(res => {
        if (current === 0) {
          setList([...res.data.records]);
        } else {
          setList([...list, ...res.data.records]);
        }
        console.log('进入到这里', res.data.total);
        setTotal(res.data.total);
      })
      .catch(err => {
        console.error(err);
      });
  };
  const handleTouchStart = event => {
    const touch = event.touches[0];
    setInStart(touch.pageX);
  };

  const handleTouchMove = event => {
    const touch = event.touches[0];
    setInEnd(touch.pageX);
  };
  const handeLast = () => {
    let num = currentIndex;
    if (currentIndex === 0) {
      Toast.info('已经是第一张了！');
    } else {
      setCurrentIndex(num - 1);
    }
  };

  useEffect(() => {
    console.log('currentIndex', currentPage);
    getPageData(currentPage, 1);
  }, [currentPage]);

  const handeNext = debounce(() => {
    console.log('点击了这里', currentIndex, total);
    let num = currentIndex;
    if (currentIndex < Number(total - 1)) {
      let pageNum = currentPage;
      setCurrentPage(pageNum + 1);
      // getPageData(pageNum + 1, 1);
      setCurrentIndex(num + 1);
    } else {
      Toast.info('已经是最后一张了！');
    }
  }, 300);

  // const handeNext = () => {
  //   console.log('点击了这里', currentIndex, total)
  //   let num = currentIndex;
  //   if (currentIndex < Number(total - 1)) {
  //     let pageNum = currentPage;
  //     setCurrentPage(pageNum + 1);
  //     getPageData(pageNum + 1, 1);
  //     setCurrentIndex(num + 1);
  //   } else {
  //     Toast.info('已经是最后一张了！');
  //   }
  // };
  const handleTouchEnd = () => {
    let num = currentIndex;
    if (inEnd - inStart > 0) {
      if (currentIndex === 0) {
        Toast.info('已经是第一张了！');
      } else {
        setCurrentIndex(num - 1);
      }
    } else {
      if (currentIndex < Number(total - 1)) {
        let pageNum = currentPage;
        setCurrentPage(pageNum + 1);
        getPageData(pageNum + 1, 1);
        setCurrentIndex(num + 1);
      } else {
        setCurrentIndex(0);
      }
    }
    setInStart(0);
    setInEnd(0);
  };
  return (
    <>
      <div className={styles['love-station-photo']}>
        {/* <div className={styles['share']}>分享</div> */}
        {/* <div className={styles['block']}></div> */}
        {/* <div className={styles['text']}></div> */}
        <div
          className={styles['photoList']}
          // onTouchStart={handleTouchStart}
          // onTouchMove={handleTouchMove}
          // onTouchEnd={handleTouchEnd}
        >
          {list[currentIndex] ? (
            <img
              className={styles['photoListItem']}
              src={list[currentIndex].url}
            />
          ) : (
            <div>暂无图片</div>
          )}
          <div className={styles['last']} onClick={handeLast}>
            {/* &lt; */}
            {/* <LeftOutline /> */}
          </div>
          <div className={styles['next']} onClick={handeNext}>
            {/* &gt; */}
          </div>
        </div>
        <div className={styles['page']}>
          {Number(currentIndex + 1)}/{total}
        </div>
        {/* <div className={styles['text-tip']}>多年来，广东肯德基热心公益，为广大户外劳动者送去关爱。</div> */}
        <div className={styles['back-btn']} onClick={handleGoMenu}>
          返回
        </div>
      </div>
    </>
  );
}
export default memo(LoveStationPhoto);
