// 爱心驿站首页
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.less';
import classnames from 'classnames';

function LoveStationMenu() {
  const history = useHistory();
  const [inPage, setInPage] = useState(false);
  const [currentButton, setCurrentButton] = useState(0);

  const handleGoGetIce = () => {
    history.push('/love/station-ice');
  };
  const handleGoWishes = () => {
    history.push('/love/station-wishes');
  };
  const handleGoMap = () => {
    history.push('/love/station-map');
  };
  const handleGoPhoto = () => {
    history.push('/love/station-photo');
  };

  const handleGoGame = () => {
    history.push('/familygame');
  }

  useEffect(() => {
    const animateButtons = () => {
      // 设置下一个要动画的按钮
      const nextButton = currentButton === 5 ? 1 : currentButton + 1;
      setTimeout(() => {
        setCurrentButton(nextButton);
      }, 2000); // 假设动画持续时间为2秒
    };

    animateButtons();

    // 清理函数，‌防止内存泄漏
    return () => {
      // 可以在这里取消任何订阅或定时器等
    };
  }, [currentButton]);
  return (
    <>
      <div
        className={classnames(
          styles['love-station-menu'],
          inPage ? '' : styles['in-page']
        )}
      >
        <div className={styles['block']}></div>
        <div className={styles['text']}></div>
        <div className={styles['function-area']}>
          <div className={styles['title']}></div>
          <div className={styles['main']}>
            <div className={styles['btn-grounp']}>
              <div
                id="one-btn"
                className={classnames(
                  styles['btn-grounp-item'],
                  currentButton == 1 ? styles['btn-animation'] : ''
                )}
                onClick={handleGoGetIce}
              >
                领取雪糕券
              </div>
              <div
                id="two-btn"
                className={classnames(
                  styles['btn-grounp-item'],
                  currentButton == 2 ? styles['btn-animation'] : ''
                )}
                onClick={handleGoWishes}
              >
                每月微心愿
              </div>
              <div
                id="two-btn"
                className={classnames(
                  styles['btn-grounp-item'],
                  currentButton == 3 ? styles['btn-animation'] : ''
                )}
                onClick={handleGoGame}
              >
                家庭教育游戏
              </div>
              <div
                id="three-btn"
                className={classnames(
                  styles['btn-grounp-item'],
                  currentButton == 4 ? styles['btn-animation'] : ''
                )}
                onClick={handleGoMap}
              >
                查找更多驿站
              </div>
              <div
                id="four-btn"
                className={classnames(
                  styles['btn-grounp-item'],
                  currentButton == 5 ? styles['btn-animation'] : ''
                )}
                onClick={handleGoPhoto}
              >
                肯德基暖心时刻
              </div>
            </div>
          </div>
        </div>
        <div className={styles['bottom']}>
          <div className={styles['egg']}></div>
          <div className={styles['gift']}></div>
        </div>
      </div>
    </>
  );
}
export default memo(LoveStationMenu);
