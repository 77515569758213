export const setCookie = (name, value, time = 72) => {
  var currentTime = new Date();
  currentTime.setTime(currentTime.getTime() + 60 * 60 * 1000 * time);
  window.document.cookie =
    name + '=' + value + ';path=/;expires=' + currentTime.toGMTString();
};

export const getCookie = name => {
  var str = document.cookie;
  if (str.length > 0) {
    let ary = str.split(';');
    let aryName = {};
    for (var i = 0; i < ary.length; i++) {
      let name=ary[i].split('=')[0].trim()
      aryName[name] = ary[i].split('=')[1];
    }
    return aryName[name];
  }
};
