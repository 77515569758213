//家庭教育游戏
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.less';
import classnames from 'classnames';
import imgUrl from '../../assets/love_station/QRcode.jpg';
import { postNativeRequestPromise } from '../../utils/naviteRequestUtil';

function FamilyGame() {
  useEffect(() => {
    postNativeRequestPromise("/inner/h5/visit/addRecord", {pageName: 'familygame'}).then(resp => {
      console.log(resp);
    })
  }, [])
  const history = useHistory();
  const handleBack = () => {
    history.goBack();
  };
  const handleLoad = () => {
    console.log('点击了下载');
    const imgUrl =
      'https://kfc-love-station.oss-cn-guangzhou.aliyuncs.com/game.jpg';
    const a = document.createElement('a');
    a.href = imgUrl;
    a.target = '_blank';
    a.click();
  };
  return (
    <>
      <div className={styles['wrap']}>
        <div className={styles['img-wrapper']}>
          <img className={styles['img-bg']} src={imgUrl} alt="" />
        </div>
        <div onClick={handleBack} className={styles['btn_back']}></div>
        {/* <div onClick={handleLoad} className={styles['btn_load']}>
                    
                </div> */}
      </div>
    </>
  );
}

export default FamilyGame;
