import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.less';

function LoveStationNoStarted() {
  const history = useHistory();
  const handleGoMenu = () => {
    history.push('/love/station-menu');
  };

  return (
    <>
      <div className={styles['love-station-reward']}>
        <div className={styles['top']}></div>
        <div className={styles['question']}>
          <div className={styles['title']}>活动未开始</div>
          <div className={styles['gift-icon']}></div>
          <div className={styles['receive-btn']}>暂未开始</div>
        </div>
      </div>
    </>
  );
}
export default memo(LoveStationNoStarted);
