import { isUndefined } from 'lodash';

export const getVwUnit = (num: number): number => num / window.screen.width * 100;

export const waitingMillSeconds = (ms: number) => new Promise(a => setTimeout(a, ms));

export const getLevelTextAndBgColor = (level: number | undefined) => {
  if (isUndefined(level)) {
    return { color: '', bgColor: '', text: '' };
  } else if (level <= 2) {
    return { color: '#63CA60', bgColor: ' #E6F7E6', text: '普通' };
  } else if (level <= 4) {
    return { color: '#FF9A24', bgColor: ' #FFF3E5', text: '重要' };
  } else {
    return { color: '#FF6A53', bgColor: '#fee', text: '紧急' };
  }
};

export function getUUid () {
  let s = [];
  let hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4';
  // @ts-ignore
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = '0';

  return s.join('');
}

