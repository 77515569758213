import { postRequest, getWX } from './requestUtil';
import { getUUid } from './index';
import { getCookie } from '../utils/cookie';

export function postNaviteRequest(url: string, data: string, cb: string): void {
  try {
    (window as any).NativeHandler.nativeRequest(url, data, cb);
    // postRequest(`/api${url}`, data, {
    //   headers: {
    //     'content-type': 'application/json',
    //     qtToken: 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyYzkyNGI3Zjc3NmQ1MzBkMDE3Nzg0ZTI3ZGE2MDAxMiIsInRlbmFudElkIjoxLCJhY2NvdW50IjoiMTc2MjU5MTAwMDEiLCJwaG9uZSI6Ijg2LTE3NjI1OTEwMDAxIiwiZW1haWwiOiIxNzYyNTkxMDAwMUBhYWEuY24iLCJuaWNrTmFtZSI6IuiDoee6ouWGmyIsInJlYWxOYW1lIjoi6IOh57qi5YabIiwiYXZhdGFyIjoiaHR0cHM6Ly9oaWZvcmNlLW9zcy1zei1kZXYub3NzLWNuLXNoZW56aGVuLmFsaXl1bmNzLmNvbS9zbWFydC1jaXR5L2V2ZW50LWZpbGUtdXBsb2FkLzIwMjEwMjA5L2Y3MDZhN2M3YTJhNWJiOWZiNGMxM2M3NTk5N2RmNTVmLmpwZyIsImp3dElkIjoiZmZiYjI5N2FiMTVlNGE0NGE1NWU1ZTg2ZDhjZTM2YTMiLCJpc3MiOiJRdWFkVGFsZW50IiwiaWF0IjoxNjE2NzQwNDUxLCJleHAiOjE2MTY3ODM2NTF9.3DaEsMuT-9e_9CW4k30NttE2EcyCBohudBGSD1L5BJi26_7Rd7pEKefbstkkE2pOzwqgqo6ybbRmi-AATU2RXw'
    //   }
    // }).then(res => {
    //   // @ts-ignore
    //   if (cb && window[cb]) {
    //     // @ts-ignore
    //     window[cb](JSON.stringify(res.data));
    //   }
    // });
  } catch (e: any) {}
}

function jsonEscape(str: string) {
  return str
    .replace(/\n/g, '\\\\n')
    .replace(/\r/g, '\\\\r')
    .replace(/\t/g, '\\\\t');
}

export function voiceRecRecognize(callback: string) {
  if ((window as any).hasOwnProperty('NativeHandler')) {
    (window as any).NativeHandler.voiceRecRecognize(callback);
  }
}

export function stopVoiceRecognize() {
  if ((window as any).hasOwnProperty('NativeHandler')) {
    (window as any).NativeHandler.stopVoiceRecognize();
  }
}
export function postNativeRequestPromise(url: string, data: object) {
  return new Promise((a, b) => {
    const reqId = 'cb' + getUUid();
    (window as any)[reqId] = (res: string) => {
      (window as any)[reqId] = undefined;
      a(JSON.parse(jsonEscape(res)));
    };
    try {
      if ((window as any).hasOwnProperty('NativeHandler')) {
        (window as any).NativeHandler.nativeRequest(
          url,
          JSON.stringify(data),
          reqId
        );
      } else {
        postRequest(url, data, {
          headers: {
            'content-type': 'application/json',
            mobile: getCookie('x-phone'),
            token: getCookie('x-token'),
            // 'X-Base-AppId': 'com.quadtalent.wechat.official', //后端用于测试是哪个应用过来的
          },
        }).then(res => {
          a(res.data);
        });
      }
    } catch (e: any) {
      b(e);
    }
  });
}

export function displayImage(url: string) {
  try {
    (window as any).NativeHandler.displayImage(url);
  } catch (e: any) {}
}

export function finishActivity(): void {
  try {
    (window as any).NativeHandler.finishActivity();
  } catch (e: any) {}
}

export function openAlbum(): void {
  try {
    (window as any).NativeHandler.openAlbum();
  } catch (e: any) {}
}

export function takePhoto(): void {
  try {
    (window as any).NativeHandler.takePhoto();
  } catch (e: any) {}
}

export function nativeOutCall(phoneNum: string): void {
  try {
    (window as any).NativeHandler.outCall(phoneNum);
  } catch (e: any) {}
}

export function changeState(key: string, value: string): void {
  try {
    (window as any).NativeHandler.changeState(key, value);
  } catch (e: any) {}
}

export function getState(key: string): any {
  try {
    return (window as any).NativeHandler.getState(key);
  } catch (e: any) {}
}

export function openHall(ticketId: string, title: string, taskKey: string) {
  try {
    (window as any).NativeHandler.openHall(ticketId, title, taskKey);
  } catch (e: any) {}
}

export function nativeDoAttend(timeStamp: string) {
  try {
    (window as any).NativeHandler.doAttend(timeStamp);
  } catch (e: any) {}
}

export function getLastAttend() {
  try {
    return (window as any).NativeHandler.getLastAttend();
  } catch (e: any) {}
}

export function getUserId() {
  try {
    return (window as any).NativeHandler.getUserId();
  } catch (e: any) {}
}

export function getUserInfo(): LoginDetail | null {
  try {
    return JSON.parse(
      (window as any).NativeHandler.getUserInfo()
    ) as LoginDetail;
  } catch (e: any) {
    return null;
  }
}

export function getCurrentGrid(): string | null {
  try {
    return (window as any).NativeHandler.getCurrentGrid();
  } catch (e: any) {
    return null;
  }
}

export function openWebView(url: string, title: string) {
  console.log('openWebView');
  try {
    (window as any).NativeHandler.openWebView(url, title);
  } catch (e: any) {}
}

export function openMap() {
  console.log('openWebView');
  try {
    (window as any).NativeHandler.openMap();
  } catch (e: any) {}
}

export function getLocation(): number[] {
  console.log('getLocation');
  try {
    const location = JSON.parse(
      (window as any).NativeHandler.getLocation()
    ) as number[];
    return location;
  } catch (e: any) {
    console.log('not found getLocation');
    return [0, 0];
  }
}

export function openRoute(lon: number, lat: number) {
  try {
    (window as any).NativeHandler.openRoute(lon, lat);
  } catch (e: any) {}
}
export async function getWxConfig(url?: string) {
  const res = await getWX('/mobile/api/user/wechat/ticket', {
    params: { url: url },
  });
  if (res) {
    return res;
  }
}
