import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import Auth from '../modules/Auth';

// 爱心驿站
import LoveStation from '../modules/LoveStation';
import LoveStationMenu from '../modules/LoveStationMenu';
import LoveStationIce from '../modules/LoveStationIce';
import LoveStationTicket from '../modules/LoveStationTicket';
import LoveStationWishes from '../modules/LoveStationWishes';
import LoveStationPhoto from '../modules/LoveStationPhoto';
import LoveStationReward from '../modules/LoveStationReward';
import LoveStationNoStarted from '../modules/LoveStationNoStarted';
import LoveStationReceivedAlready from '../modules/LoveStationReceivedAlready';
import LoveStationMap from '../modules/LoveStationMap';
import FamilyGame from '../modules/FamilyGame';

const routers = () => {
  return (
    <Router>
      <Suspense fallback={<div>loading</div>}>
        <CacheSwitch>
          {/* 短信验证码登录页面 */}
          <Route path="/auth" component={Auth} />
          <Route path="/familygame" component={FamilyGame} />
          {/* 爱心驿站首页 */}
          <Route path="/love/station" component={LoveStation} />
          <Route path="/love/station-menu" component={LoveStationMenu} />
          <Route path="/love/station-ice" component={LoveStationIce} />
          <Route path="/love/station-ticket" component={LoveStationTicket} />
          <Route path="/love/station-wishes" component={LoveStationWishes} />
          <Route path="/love/station-photo" component={LoveStationPhoto} />
          <Route path="/love/station-reward" component={LoveStationReward} />
          <Route
            path="/love/station-no-started"
            component={LoveStationNoStarted}
          />
          <Route
            path="/love/station-received-already"
            component={LoveStationReceivedAlready}
          />
          <Route path="/love/station-map" component={LoveStationMap} />
          <Route path="/" component={LoveStation} />
        </CacheSwitch>
      </Suspense>
    </Router>
  );
};

export default routers;
