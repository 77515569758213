// 爱心驿站首页
import { memo, useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.less';
import { Map, Marker } from 'react-amap';
import { postNativeRequestPromise } from '../../utils/naviteRequestUtil';

function LoveStationMap() {
  const mapRef = useRef(null);
  const [searchValue, setSearchValue] = useState('爱心驿站');
  const [zoom, setZoom] = useState(10);
  const [pointsList, setPointsList] = useState([
    {
      position: [113.264499, 23.130061],
      name: '点1',
    },
  ]);
  let map = null;
  const AMap = window.AMap;
  const history = useHistory();
  useEffect(() => {
    postNativeRequestPromise("/inner/h5/visit/addRecord", {pageName: 'stationMap'}).then(resp => {
      console.log(resp);
    })
  }, [])
  useEffect(() => {
    setTimeout(() => {
      handleSearch();
    }, 300);
  }, []);
  const handleGoMenu = () => {
    history.push('/love/station-menu');
  };
  const handleSearch = () => {
    AMap.service(['AMap.PlaceSearch'], function () {
      //构造地点查询类
      var placeSearch = new AMap.PlaceSearch({
        city: '广州市', // 兴趣点城市
      });
      //关键字查询
      placeSearch.search(searchValue, (status, result) => {
        console.log(status, result, 'status, result');
        if (result.info === 'OK') {
          const searchRes = result.poiList.pois;
          const tempList = [];
          searchRes.forEach((item, index) => {
            tempList.push({
              name: item.name,
              position: [item.location.lng, item.location.lat],
            });
          });
          setPointsList([...tempList]);
        }
      });
    });
  };
  const handleIptValue = e => {
    setSearchValue(e.target.value);
  };
  const handleZoomChange = newZoom => {
    setZoom(newZoom); // 更新zoom状态
  };
  return (
    <>
      <div className={styles['love-station-map']}>
        <div className={styles['ipt-box']}>
          <input
            value={searchValue}
            className={styles['ipt']}
            placeholder="搜索地点"
            onChange={handleIptValue}
            disabled
          />
          {/* <button className={styles['btn']} onClick={handleSearch}>
            搜索
          </button> */}
        </div>
        <div className={styles['tip']}>缩放地图可查看周边爱心驿站具体位置</div>
        <div className={styles['map']}>
          <Map
            amapKey="525dc8ff94911905a2caa137ad6a75c1"
            ref={mapRef}
            center={[113.264499, 23.130061]}
            zoom={zoom}
          >
            {pointsList.map((point, index) => (
              <Marker key={index} position={point.position} text={point.name} />
            ))}
            <div
              className={styles['zomm-btn-big']}
              onClick={() => handleZoomChange(zoom + 1)}
            >
              <span>+</span>
            </div>
            <div
              className={styles['zomm-btn']}
              onClick={() => handleZoomChange(zoom - 1)}
            >
              <span>-</span>
            </div>
          </Map>
        </div>
        <div className={styles['back-btn']} onClick={handleGoMenu}>
          返回
        </div>
      </div>
    </>
  );
}
export default memo(LoveStationMap);
