import { memo, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './index.less';
import classnames from 'classnames';
import { postNativeRequestPromise } from '../../utils/naviteRequestUtil';
// import { Toast } from '_antd-mobile@2.3.4@antd-mobile';
import { Toast, Modal } from 'antd-mobile';
import { getCookie } from '../../utils/cookie';

function LoveStationWishes() {
  const [activeIndex, setActiveIndex] = useState(0);
  const history = useHistory();
  const [list, setList] = useState([]);
  const [question, setQuestion] = useState('');
  const [noMessage, setNoMessage] = useState('');
  const [record, setRecord] = useState();
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const [hasLogin, setHasLogin] = useState(false)
  const defaultData = {
        "questionId": 1,
        "question": "如果请你吃一顿肯德基解压",
        "status": 0,
        "wishOptionsWebVOList": [
            {
                "questionId": 1,
                "optionsId": 1,
                "options": "A.一份丰盛早餐带来全天好心情"
            },
            {
                "questionId": 1,
                "optionsId": 2,
                "options": "B.下班给孩子带一份儿童餐和快乐"
            },
            {
                "questionId": 1,
                "optionsId": 3,
                "options": "C.一杯提神醒脑的咖啡"
            }
        ]
    }

  useEffect(() => {
    postNativeRequestPromise("/inner/h5/visit/addRecord", {pageName: 'stationWishes'}).then(resp => {
      console.log(resp);
    })
  }, [])

  useEffect(() => {
    // setTimeout(() => {
      handleHasLogin();
    // }, 1000);
  }, []);

  const handleHasLogin = () => {
    let token = getCookie('x-token');
    if (token == '' || token == null) {
      
      Modal.alert('未登录', '您还没有登录，请先登录！！', [
        {
          text: '取消',
          onPress: () => {},
        },
        {
          text: '去登录',
          onPress: () => {
            localStorage.setItem('x-path', location.pathname);
            setTimeout(() => {
              history.push('/auth');
            }, 1000);
          },
        },
      ]);
      // Toast.info(
      //   '你还未登录！后期等登录接口会跳转登录页面进行登录，现在可直接查看改页面！'
      // );
      getWish();
    } else {
      setHasLogin(true)
      getWish();
    }
  };

  const handleGoMenu = () => {
    // history.push('/love/station-menu');
    history.goBack();
  };
  const handleChange = index => {
    setActiveIndex(index);
  };
  // useEffect(() => {
  //   getWish();
  // }, []);
  //获取抽奖记录
  const getCurrentMonthRecord = () => {
    postNativeRequestPromise('/inner/h5/wish/prize/getCurrentMonthRecord', {})
      .then(res => {
        if (res.data && res.data.prizeDrawTime) {
          // setRecord(res.data);
          // console.log('这个月已经抽取过了', res.data)
          Toast.info('本月已经领取过奖品,请下月再来');
        } else {
          handlePrizeDraw();
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  //获取题目
  const getWish = () => {
    postNativeRequestPromise('/inner/h5/wish/prize/getWish', {})
      .then(res => {
        
        if (res.data.question) {
          setList(res.data.wishOptionsWebVOList);
          setQuestion(res.data);
        } else {
          Toast.info(res.message);
          setNoMessage(res.message);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  //抽奖
  const handlePrizeDraw = () => {
    postNativeRequestPromise('/inner/h5/wish/prize/prizeDraw', {
      optionsId: list[activeIndex].optionsId,
    })
      .then(res => {
        if (res.code == '00000') {
          if (res.data.prizeUrl) {
            // window.open(res.data.prizeUrl, '_blank')
            location.href = res.data.prizeUrl
            var a = document.createElement('a')
            a.setAttribute("href", res.data.prizeUrl)
            document.body.append(a)
            a.click()
            // Toast.info(res.message);
            // history.push('/love/station-reward', {
            //   info: res.data,
            // });
          }
        } else {
          Toast.info(res.message);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  return (
    <>
      <div className={styles['love-station-wishes']}>
        <div className={styles['top']}></div>
        <div
          className={styles['activity-rules']}
          onClick={() => setVisible(true)}
        >
          活动规则
        </div>
        {question?.question ? (
          <div className={styles['question']}>
            <div className={styles['title']}>{question?.question}</div>
            {question?.question ? <div className={styles['query']}></div> : ''}
            <div className={styles['list']}>
              {list.map((item, index) => {
                return (
                  <div
                    className={classnames(
                      styles['item'],
                      activeIndex === index ? styles['active_item'] : ''
                    )}
                    onClick={() => handleChange(index)}
                    key={index}
                  >
                    <span className={styles['dot']}></span>
                    <span className={styles['item-text']}>{item.options}</span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className={styles['question']}>
            <div className={styles['title']}>
              {defaultData.question}
            </div>
            <div className={styles['query']}></div>
            <div className={styles['list']}>
              {defaultData.wishOptionsWebVOList.map((item, index) => {
                return (
                  <div
                    className={classnames(
                      styles['item'],
                      activeIndex === index ? styles['active_item'] : ''
                    )}
                    key={index}
                  >
                    <span className={styles['dot']}></span>
                    <span className={styles['item-text']}>{item.options}</span>
                  </div>
                );
              })}
            </div>
            {/* {record ? (
              <div className={styles['receive-btn']}>
                {`已经抽过!还有${record?.prizeDrawTime}天才能重新抽奖！`}
              </div>
            ) : (
              ''
            )} */}
          </div>
        )}
        {question?.question ? (
          <>
            <div
              className={styles['draw-prizes-btn']}
              onClick={getCurrentMonthRecord}
            >
              抽取奖品
            </div>
          </>
        ) : (
          <>
            <div
              className={styles['draw-prizes-btn']}
              onClick={handleHasLogin}
            >
              抽取奖品
            </div>
          </>
        )}
        <div className={styles['back-btn']} onClick={handleGoMenu}>
          返回
        </div>
        
        {visible && (
          <div>
            <div className="am-picker-popup-mask"></div>
            <div className="am-picker-popup-wrap " role="dialog">
              <div
                role="document"
                className="am-picker-popup"
                style={{
                  height: '98vh',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  margin: 'auto',
                }}
                onClick={() => setVisible(false)}
              >
                <div
                  className="rule-body"
                  style={{
                    position: 'absolute',
                    left: '63px',
                    top: '22vh',
                    width: '70%',
                    height: '55%',
                  }}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                      }}
                    >
                      活动日期：
                    </span>
                    {/* 2024年8月28日-11月30日 */}
                    2024年9月23-2024年11月30日
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                      }}
                    >
                      活动门店：
                    </span>
                    广州市范围内肯德基餐厅
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                      }}
                    >
                      活动规则：<br/>
                    </span>
                    1、每月(自然月)共抽出100份“微心愿专享”免费卡券送给户外劳动者；未中奖参与者可获得9.9元指定中杯现磨咖啡券一张（限10000张），所有奖品领完即止。<br/>
2、活动仅限户外劳动者参与，每位参与者（同一设备、同一手机号）每月仅限参与一次抽奖。<br/>
领券方式: 抽取奖品后跳转至“肯德基+”小程序进行领取，领取后卡券将发放至会员帐户。可在【肯德基APP/小程序】-【我的】-【卡券】中查看。<br/>
注意事项：<br/>
1、产品及包装以餐厅实际供应实物为准；<br/>
2、活动最终解释权归主办方所有。<br/>
                    {/* 每月抽出100份免费餐点卡券送给户外劳动者，未中奖参与者可获得9.9元指定中杯现磨咖啡券
                    一张。 */}
                    {/* 自然周领取一次，领取后自然周有效。 */}
                  </p>

                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default memo(LoveStationWishes);
