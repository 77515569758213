import { atom, selector } from 'recoil';
import locales from '../locales';

export const areaList = atom<CompanyCardItem[]>({
  key: 'areaList',
  default: []
});

export const showCommArea = atom<boolean>({
  key: 'showCommArea',
  default: false
})

export const showWorkDetail = atom({
  key: 'showWorkDetail',
  default: false
})

export const showLoading = atom({
  key: 'showLoading', 
  default: false
})

export const showBtnDiv = atom({
  key: 'showBtnDiv', 
  default: false
})

export const btnHasAction = atom({
  key: 'btnHasAction',
  default: false
})

export const hasBeenDelete = atom({
  key: 'deleteId',
  default: ''
})
export const demoCount = atom({
  key: 'demoCount',
  default: 0,
});
export const userInfoM = atom({
  key: 'userInfoM',
  default: {
    roleCode: ''
  }
})
export const processList = atom({
  key: 'processList',
  default: []
})

export const intlConfig = atom({
  key: 'intlConfig',
  default: locales.zh_CN,
});
export const SEX_MAP = {
  '1':'男',
  '2':'女',
  '9':'未知'
}
export const ComplaintType:Map<string, string> = new Map([
  ["F-21-06","公共投诉"],
  ["F-21-0601","邻里关系"],
  ["F-21-0602","公共设施"],
  ["F-21-0603","交通出行"],
  ["F-21-0604","医疗卫生"],
  ["F-21-0605","教育"]

]);
export const workOrderList = atom({
  key: 'workOrderList',
  default: {
    acceptList: [],
    handleList: [],
    checkList: []
  }
});
export const modalStatus = atom({
  key: 'modalStatus',
  default: false
})
export const focusWorked = atom({
  key: 'focusWorked',
  default: []
})

export const activeTab = atom({
  key: 'activeTab',
  default: 0
})
export const acceptTotal = atom({
  key: 'acceptTotal',
  default: 0,
});
export const handleTotal = atom({
  key: 'handleTotal',
  default: 0,
});
export const checkTotal = atom({
  key: 'checkTotal',
  default: 0,
});

export const acceptList = selector({
  key: 'acceptListSelector',
  get: ({ get }) => get(workOrderList).acceptList,
  set: ({ set }, newValue: any) => {
    set(workOrderList, (prevValue => {
      return { ...prevValue, acceptList: newValue };
    }));
  }
});

export const handleList = selector({
  key: 'handleListSelector',
  get: ({ get }) => get(workOrderList).handleList,
  set: ({ set }, newValue: any) => {
    set(workOrderList, (prevValue => {
      return { ...prevValue, handleList: newValue };
    }));
  }
});

export const checkList = selector({
  key: 'checkListSelector',
  get: ({ get }) => get(workOrderList).checkList,
  set: ({ set }, newValue: any) => {
    set(workOrderList, (prevValue => {
      return { ...prevValue, checkList: newValue };
    }));
  }
});

export const personSelected = atom<any[]>({
  key: 'personSelected',
  default: []
});
//更改会议存储信息
export const upCard = atom<any[]>({
  key: 'upCard',
  default: []
})
//点击step2返回按钮时存储的首页值
export const backValue = atom<any[]>({
  key: 'backValue',
  default: []
})
export const memberCount = selector({
  key: 'charCountState', // 唯一标识
  get: ({get}) => {
    const member = get(personSelected);

    return member.length;
  },
});

export const personSelectedList = atom<any[]>({
  key: 'personSelectedList',
  default: []
});

export const nameGridId = atom<any[]>({
  key: 'nameGridId',
  default: []
})

export const mineId = atom<string>({
  key: 'mineId',
  default: ''
})

export const mineRespon = atom<string>({
  key: 'mineRespon',
  default: ''
})

export const isMine = atom<string>({
  key: 'isMine',
  default: ''
})


export const distributReason = atom<string>({
  key: 'distributReason',
  default: ""
});

export const loginDetail = atom<LoginDetail | null>({
  key: 'loginDetail',
  default: null 
})

export const getTaskDataAgain = atom({
  key: 'getTaskDataAgain',
  default: {handle:false,check:false}
});

export const superviseHandleList = atom<any[]>({
  key: 'superviseHandleList',
  default: []
});
export const superviseHandlePageIndex = atom<number>({
  key: 'superviseHandlePageIndex',
  default: 1
});


export const superviseExamList = atom<any[]>({
  key: 'superviseExamList',
  default: []
});
export const superviseExamPageIndex = atom<number>({
  key: 'superviseExamPageIndex',
  default: 1
});


export const superviseFinishList = atom<any[]>({
  key: 'superviseFinishList',
  default: []
});
export const superviseFinishPageIndex = atom<number>({
  key: 'superviseFinishPageIndex',
  default: 1
});

export const refreshTicketListFlag = atom<number> ({
  key: 'refreshTicketListFlag',
  default: 1
})

export const expandState = atom({
  key: 'expandState',
  default: false
})
export const GDPState = atom({
  key: 'GDPState',
  default: 0.0
})
export const GDP_incre = atom({
  key: 'GDP_incre',
  default: 0.0
})

export const MemberInfo = atom({
  key: 'MemberInfo',
  default: [{
    gridId: '',
    areaCode: '',
    gridName: ''
  }]
})

export const GridListInfo = atom<GridItem[]>({
  key: 'GridListInfo',
  default: []
})


